<template>
    <div>
        <a-modal v-model:visible="visible" width="800px">
            <template #title>
                <modalHeader title="选择显示字段"></modalHeader>
            </template>
            <template #footer>
				<a-button @click="close">取消</a-button>
                <a-button @click="renew">恢复默认</a-button>
				<a-button @click="onSubmit" type="primary">确定</a-button>
			</template>
           <a-form :label-col="{ span: 4 }">
                <p class="ui-formTitle">1、基本信息</p>
                <a-checkbox-group v-model:value="selectValue[0]" :options="options1" @change="onSelect"/>
                <p class="ui-formTitle">2、建设用地储备</p>
                <a-checkbox-group v-model:value="selectValue[1]" :options="options2" @change="onSelect"/>
                <p class="ui-formTitle">3、项目立项</p>
                <a-checkbox-group v-model:value="selectValue[2]" :options="options3" @change="onSelect"/>
                <p class="ui-formTitle">4、项目招标</p>
                <a-checkbox-group v-model:value="selectValue[3]" :options="options4" @change="onSelect"/>
                <p class="ui-formTitle">5、项目施工</p>
                <a-checkbox-group v-model:value="selectValue[4]" :options="options5" @change="onSelect"/>
                <p class="ui-formTitle">6、竣工验收</p>
                <a-checkbox-group v-model:value="selectValue[5]" :options="options6" @change="onSelect"/>  
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import modalHeader from '@/components/modalHeader/index.vue';
import {
		getSelectedField,
		setSelectedField
	} from '@/service/modules/project.js';
export default {
    components:{modalHeader},
    data(){
        return{
            visible:false,
            selectValue:[],
            tempSelectValue:[],
            resultList:[
				{
					title: '序号',
					align: 'center',
					key: 'index',
					fixed: 'left',
					rowDrag: true,
					width: 50
				}
			],//option合并
            options1:[
                // {label:'项目名称',value:1,title:'项目名称',key:'name',dataIndex:'name'},
				{	
					label:'项目名称',
					title: '项目名称',
					value:1,
					align: 'center',
					dataIndex: "name",
					key: 'name',
					width: 100,
					fixed:'left',
					resizable: true,
					sorter: true
				}, 
                // {label:'项目分类',value:2,title:'项目分类',key:'',dataIndex:''},
				{
					label:'项目分类',
					title: '项目分类',
					align: 'center',
					key: "classify",
					fixed:'left',
					value:2,
					dataIndex: "classify",
					width: 80,
					resizable: true,
					sorter: true
				},
                // {label:'项目阶段',value:3,title:'项目阶段',key:'',dataIndex:''},
				{
					label:'项目阶段',
					title: '项目阶段',
					align: 'center',
					key: 'stage',
					dataIndex: "stage",
					value:3,
					width: 80,
					fixed:'left',
					resizable: true,
					sorter: true
				}, 
				{label:'施工单位',value:62,width: 100,align: 'center',title:'施工单位',dataIndex: 'constructionCompany',key: 'constructionCompany',fixed:'left',sorter: true},
				{label:'是否重大项目',value:33,width: 100,align: 'center',title:'是否重大项目',key:'is_important',dataIndex:'is_important',fixed:'left',sorter: true},
				{label:'主管单位',value:7,width: 100,title:'主管单位',align: 'center',key:'mangerUnit',dataIndex:'mangerUnit',fixed: 'left',sorter: true},
				{label:'启动时间',value:6,width: 100,align: 'center',title:'启动时间',key:'boot_time',dataIndex:'boot_time',fixed:'left',sorter: true},
                {label:'实施单位',value:8,width: 100,align: 'center',title:'实施单位',key:'executeUnit',dataIndex:'executeUnit',resizable: true,fixed: 'left',sorter: true},
                {label:'主管单位联系人',value:4,width: 100,align: 'center',title:'主管单位联系人',key:'manger_username',dataIndex:'manger_username', resizable: true,sorter: true},
                {label:'主管单位联系人电话',value:5,width: 100,align: 'center',title:'主管单位联系人电话',key:'manger_user_phone',dataIndex:'manger_user_phone', resizable: true,sorter: true},
                {label:'项目负责人',value:9,width: 100,align: 'center',title:'项目负责人',key:'head_username',dataIndex:'head_username',sorter: true},
                {label:'负责人电话',value:10,width: 100,align: 'center',title:'负责人电话',key:'head_user_phone',dataIndex:'head_user_phone',sorter: true},
                {label:'分管领导',value:11,width: 100,align: 'center',title:'分管领导',key:'leader_username',dataIndex:'leader_username',sorter: true},
                {label:'分管领导电话',value:12,width: 100,align: 'center',title:'分管领导电话',key:'leader_phone',dataIndex:'leader_phone',sorter: true},
                {label:'已签订合同金额',value:13,width: 100,title:'已签订合同金额',key:'projectContractAmount',dataIndex:'projectContractAmount',align: 'center',resizable: true,sorter: true},
                {label:'已拨付款',value:14,width: 100,title:'已拨付款',key:'',dataIndex:'',children:[
                    {title: '设计费',align: 'center',width: 180,key: 'designAmount',dataIndex: "designAmount",resizable: true,sorter: true},
                    {title: '工程款',align: 'center',width: 180,key: 'projectAmount',resizable: true,sorter: true},
                    {title: '工程进度',align: 'center',width: 180,key: 'schedulePercent',dataIndex: 'schedulePercent',resizable: true,sorter: true},
                    {title: '监理费',align: 'center',width: 180,key: 'watchAmount',resizable: true,sorter: true},
                    {title: '全过程咨询费',align: 'center',width: 180,key: 'saf',resizable: true,sorter: true},
                    {title: '其他',align: 'center',width: 180,key: 'other',resizable: true,sorter: true}
                ]},
                {label:'待拨付款',value:15,width: 100,title:'待拨付款',align: 'center',key:'pendingPayment',dataIndex:'pendingPayment',sorter: true},
            ],
			  options2:[{label:'建设用地来源',value:16,width: 100,align: 'center',title:'建设用地来源',key:'buildLandSourceType',dataIndex:'buildLandSourceType', resizable: true,sorter: true},
						{label:'取得方式',value:17,width: 100,align: 'center',title:'取得方式',dataIndex: 'buildLandGainType',key: 'buildLandGainType', resizable: true,sorter: true},
						{label:'是否前置办理不动产权证',value:18,width: 100,align: 'center',title:'是否前置办理不动产权证',dataIndex: 'buildLandRegisterStatus', resizable: true, key: 'buildLandRegisterStatus',sorter: true},
						{label:'三通一平',value:19,width: 100,title:'三通一平',key:'',dataIndex:'',children:[
							{title: '有无支出',width: 100,dataIndex: 'buildLandSpeedStatus',align: 'center',key: 'buildLandSpeedStatus',resizable: true,sorter: true},
							{title: '完工量',width: 100,dataIndex: 'buildLandSpeedCompletedPercent',align: 'center',key: 'buildLandSpeedCompletedPercent', resizable: true,sorter: true},
							{title: '完工进度',width: 100, dataIndex: 'paymentPercentage',align: 'center',key: 'paymentPercentage', resizable: true,sorter: true},
							{title: '有无审计',width: 100,dataIndex: 'buildLandCostAuditStatus',align: 'center',key: 'buildLandCostAuditStatus', resizable: true,sorter: true},
						]},
						{label:'有无污染状况调查',value:20,width: 100,align: 'center',title:'有无污染状况调查',dataIndex: 'buildLandResultStatus',key: 'buildLandResultStatus'},
						{label:'土地性质',value:59,width: 100,align: 'center',title:'土地性质',dataIndex: 'buildLandNatureHasForestry',key: 'buildLandNatureHasForestry', resizable: true,sorter: true},
					],
			  options3:[{label:'投资概算',value:21,width: 100,align: 'center',title:'投资概算',key:'invest_cost',dataIndex:'invest_cost',resizable: true,sorter: true},
						{label:'资金来源',value:22,width: 100,align: 'center',title:'资金来源',key:'',dataIndex:'',children:[
							{title: '上级专项资金',dataIndex: 'projectApprovalSourceSpecialCost',align: 'center',key: 'projectApprovalSourceSpecialCost',sorter: true},
							{title: '县国资公司',dataIndex: 'projectApprovalSourceCompanyCost',align: 'center',key: 'projectApprovalSourceCompanyCost',sorter: true},
							{title: '县本级财政资金',dataIndex: 'projectApprovalSourceSourceCost',align: 'center',key: 'projectApprovalSourceSourceCost',sorter: true},
							{title: '其他来源资金',dataIndex: 'projectApprovalSourceOtherCost',align: 'center',key: 'projectApprovalSourceOtherCost',sorter: true},
						]},
						{label:'是否已立项',value:23,width: 100,align: 'center',title:'是否已立项',dataIndex: 'projectApprovalReviewStatus',key: 'projectApprovalReviewStatus',sorter: true},
						{label:'有无可研批复',value:24,width: 100,align: 'center',title:'有无可研批复',dataIndex: 'projectApprovalHasFeasibility',key: 'projectApprovalHasFeasibility',sorter: true},
						{label:'有无风险稳定评估',value:25,width: 100,align: 'center',title:'有无风险稳定评估',dataIndex: 'projectApprovalHasRisk',key: 'projectApprovalHasRisk',sorter: true},
						{label:'有无初设批复',value:26,width: 100,align: 'center',title:'有无初设批复',dataIndex: 'projectApprovalHasDesign',key: 'projectApprovalHasDesign',sorter: true},
						{label:'有无环评',value:27,width: 100,align: 'center',title:'有无环评',dataIndex: 'projectApprovalHasEnv',key: 'projectApprovalHasEnv',sorter: true},
						{label:'有无水保方案',value:28,width: 100,align: 'center',title:'有无水保方案',dataIndex: 'projectApprovalHasKeep',key: 'projectApprovalHasKeep',sorter: true},
						{label:'有无防洪评价',value:29,width: 100,align: 'center',title:'有无防洪评价',dataIndex: 'projectApprovalHasEvaluate',key: 'projectApprovalHasEvaluate',sorter: true},
						{label:'有无节能审批',value:30,width: 100,align: 'center',title:'有无节能审批',dataIndex: 'projectApprovalHasEc',key: 'projectApprovalHasEc',sorter: true},
						{label:'有无消防审批',value:31,width: 100,align: 'center',title:'有无消防审批',dataIndex: 'projectApprovalHasFire',key: 'projectApprovalHasFire',sorter: true},
						{label:'有无人防审批',value:32,width: 100,align: 'center',title:'有无人防审批',dataIndex: 'projectApprovalHasPad',key: 'projectApprovalHasPad',sorter: true},
						{label:'是否列入投资计划',value:60,width: 100,align: 'center',title:'是否列入投资计划',key:'projectApprovalIsPlan',dataIndex:'projectApprovalIsPlan',sorter: true},
					],
			  options4:[{label:'工程中标单位',value:58,width: 100,align: 'center',title:'工程中标单位',dataIndex: 'inviteTendersSuccessCompany',key: 'inviteTendersSuccessCompany',sorter: true},
						{label:'工程中标价（合同价）',value:34,width: 100,align: 'center',title:'工程中标价（合同价）',dataIndex: 'inviteTendersSuccessPrice',key: 'inviteTendersSuccessPrice',sorter: true},
						{label:'监理中标单位',value:35,width: 100,align: 'center',title:'监理中标单位',dataIndex: 'inviteTendersWatcherCompany',key: 'inviteTendersWatcherCompany',sorter: true},
						{label:'监理费用合同金额',value:36,width: 100,align: 'center',title:'监理费用合同金额',dataIndex: 'inviteTendersWatcherPrice',key: 'inviteTendersWatcherPrice',sorter: true},
						{label:'跟踪审计中标公司',value:37,width: 100,align: 'center',title:'跟踪审计中标公司',dataIndex: 'inviteTendersFlowableCompany',key: 'inviteTendersFlowableCompany',sorter: true},
						{label:'跟踪审计合同金额',value:38,width: 100,align: 'center',title:'跟踪审计合同金额',dataIndex: 'inviteTendersFlowablePrice',key: 'inviteTendersFlowablePrice',sorter: true},
						{label:'全过程咨询中标公司',value:39,width: 100,align: 'center',title:'全过程咨询中标公司',dataIndex: 'inviteTendersSafCompany',key: 'inviteTendersSafCompany',sorter: true},
						{label:'全过程咨询合同金额 ',value:40,width: 100,align: 'center',title:'全过程咨询合同金额',dataIndex: 'inviteTendersSafPrice',key: 'inviteTendersSafPrice',sorter: true}],
			  options5:[{label:'有无施工许可证',value:41,width: 100,align: 'center',title:'有无施工许可证',dataIndex: 'constructionHasPermit',key: 'constructionHasPermit',sorter: true},
						{label:'有无项目变更',value:42,width: 100,align: 'center',title:'有无项目变更',dataIndex: 'constructionHasChange',key: 'constructionHasChange',sorter: true},
						{label:'有无设备询价',value:43,width: 100,align: 'center',title:'有无设备询价',dataIndex: 'constructionHasInquiry',key: 'constructionHasInquiry',sorter: true},
						{label:'设备询价公司',value:44,width: 100,align: 'center',title:'设备询价公司',dataIndex: 'constructionInquiryCompany',key: 'constructionInquiryCompany',sorter: true},
						{label:'工程款',value:45,width: 100,align: 'center',title:'工程款',key:'',dataIndex:'',children:[
							{title: '合同金额',width: 100,align: 'center',dataIndex: 'constructionScheduleContract',key: 'constructionScheduleContract',sorter: true},
							{title: '工程完工量',width: 100,align: 'center',dataIndex: 'constructionSchedulePercent',key: 'constructionSchedulePercent',sorter: true},
							{title: '工程进度(%)',width: 100,align: 'center',dataIndex: 'progressPercent',key: 'progressPercent',sorter: true},
							{title: '核定付款比例(%)',width: 100,align: 'center',dataIndex: 'constructionSchedulePayPercent',key: 'constructionSchedulePayPercent',sorter: true}
						]},
						{label:'有无审计',value:46,width: 100,align: 'center',title:'有无审计',dataIndex: 'constructionHasAudit',key: 'constructionHasAudit',sorter: true},
						{label:'审计公司',value:47,width: 100,align: 'center',title:'审计公司',dataIndex: 'constructionAuditCompany',key: 'constructionAuditCompany',sorter: true},
						{label:'增量金额',value:61,width: 100,align: 'center',title:'增量金额',dataIndex: 'constructionChangeAddAmount',key: 'constructionChangeAddAmount',sorter: true},
					],
			  options6:[{label:'有无避雷检查',value:48,width: 100,align: 'center',title:'有无避雷检查',dataIndex: 'completionHasLpi',key: 'completionHasLpi',sorter: true},
                {label:'有无消防验收',value:49,width: 100,align: 'center',title:'有无消防验收',dataIndex: 'completionHasFire',key: 'completionHasFire',sorter: true},
                {label:'有无建设规划验收',value:50,width: 100,align: 'center',title:'有无建设规划验收',dataIndex: 'completionHasBuild',key: 'completionHasBuild',sorter: true},
                {label:'有无环评验收',value:51,width: 100,align: 'center',title:'有无环评验收',dataIndex: 'completionHasEia',key: 'completionHasEia',sorter: true},
                {label:'有无水保验收',value:52,width: 100,align: 'center',title:'有无水保验收',dataIndex: 'completionHasKeep',key: 'completionHasKeep',sorter: true},
                {label:'有无竣工验收备案 ',value:53,width: 100,align: 'center',title:'有无竣工验收备案',dataIndex: 'completionHasFiling',key: 'completionHasFiling',sorter: true},
                {label:'财务结算 ',value:54,width: 100,align: 'center',title:'财务结算',key:'',dataIndex:'',children:[
                    {title: '概算金额',value:55,width: 100,align: 'center',dataIndex: 'projectApprovalReviewTotalCost',key: 'projectApprovalReviewTotalCost',sorter: true},
                    {title: '结算金额',value:56,width: 100,align: 'center',dataIndex: 'completionAccountsCost',key: 'completionAccountsCost',sorter: true},
                    {title: '结余或超支',value:57,width: 100,align: 'center',dataIndex: 'overExpenditure',key: 'overExpenditure',sorter: true},
                ]},
            ],
        }
    },
	created() {
	},
	watch:{
          $route(to,from){
			if((to.path === '/project/projectMain'&&from.path !== '/project/projectRecord') || to.path=== '/project/projectSearch'){
                 this.selectValue = this.$getLS('selectValue')
				 this.onSubmit(1)
		    }
			// if(to.path === '/project/projectRecord'&&from.path=== '/project/projectRecord'){
			// 	this.$setLS('selectValue',this.selectValue)
			// }
		  }
	},
    mounted(){
        this.resultList = [...this.options1,...this.options2,...this.options3,...this.options4,...this.options5,...this.options6];
        if(this.selectValue.length == 0){
            this.renew();
			this.getSelFieldData()
        }
		if(this.$getLS('selectValue')){
			this.selectValue = this.$getLS('selectValue')
			this.onSubmit(1)
		}else{
			this.onSubmit(1)
		}
    },
    methods:{
		async getSelFieldData() {
			try{
				let ret = await getSelectedField({})
				if(ret.code === 200) {
					let jsData = JSON.parse(ret.data)
					this.selectValue = jsData.selectValue
					this.$setLS('selectValue',this.selectValue)
				}
			}catch(e){
				//TODO handle the exception
			}
		},
		async onSumitSelFieldData(init) {
			if(init!==1){
			  this.$setLS('selectValue',this.selectValue)
			}
			try{
				let ret = await setSelectedField({
					selectValue: this.selectValue
				})
				if(init!==1){
					if(ret.code === 200) {
					this.$message.success("保存成功")
				}
				}
	
			}catch(e){
				//TODO handle the exception
			}
		},
        open(){
            this.visible = true;
			this.getSelFieldData()
			// console.log("2222")
            this.tempSelectValue = this.deepClone();
        },
        close(){
            this.visible = false;
            this.selectValue = this.tempSelectValue;
        },
        onSubmit(init){
            let resultValue = [];
            let list =[];
			let leftList = [];//定在头的列表
            this.selectValue&&this.selectValue.forEach(item => {
                resultValue = [...resultValue,...item];
            });

            // 遍历得出对应要展示的列表头
            for(let i = 0;i < resultValue.length; i ++ ){
                for (let j = 0; j < this.resultList.length; j++) {
                    if(resultValue[i] == this.resultList[j].value){
						if(this.resultList[j].key == 'mangerUnit' || this.resultList[j].key == 'name' || this.resultList[j].key == 'classify' || this.resultList[j].key == 'executeUnit' ||
						this.resultList[j].key == 'is_important' || this.resultList[j].key == 'boot_time' || this.resultList[j].key == 'stage' ||
						this.resultList[j].key == 'constructionCompany'){
							leftList.push(this.resultList[j]);
						}else{
							list.push(this.resultList[j]);
						}
						break;
                    }
                }
            }
			list = [...leftList,...list];
            list.unshift({title: '序号',align: 'center',fixed: 'left',key: 'index',width: 80,resizable: true,rowDrag: true});
            list.push({title:'操作',key:'action',align:'center',resizable:true,fixed:'right'});
            this.$emit('newColums',list);
			// if(init === 1) this.onSumitSelFieldData(init)
			this.onSumitSelFieldData(init)
            this.visible = false;
        },
        onSelect(e,index){

            // console.log(e,index);
        },
        // 恢复默认
        renew(){
            this.selectValue = [[],[],[],[],[],[]];
            this.selectValue[0] = [7,1,2,8,33,6,3,62,13,14,15]
			// this.selectValue[2] = []
			// this.selectValue[5] = [48,49,50,51,52,53,54]
            // this.selectValue[4] = [37,38]
        },
        // 深拷贝二维数组
        deepClone(){
            let list = [[],[],[],[],[],[]];
            for(let i = 0;i<this.selectValue.length; i++ ){
                list[i] = this.selectValue[i];
            }
            return list;
        }
    }
}
</script>

<style scoped>
.ui-formTitle{
    font-size: 15px;
    font-weight: 800;
}
</style>